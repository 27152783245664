p {
  text-align: center;
}

img {
  pointer-events: none;
}

.ant-click-animating-node {
  display: none !important;
}
.ant-switch-handle .ant-switch .ant-switch-checked {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.error-underline {
  text-decoration: underline;
  text-decoration-color: red;
}
